import React from 'react';
import {Link} from 'gatsby';
import {Helmet} from 'react-helmet';
import {useSiteMetadata} from '../utils/use-site-metadata';

import Favicon from '../images/favicon.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import OpenGraphImage from '!file-loader?{"name":"static/[name]-[hash].[ext]"}!../images/social-pulse.png';

export default function Layout({children, variant}) {
  const {title, descriptionHtml, siteUrl} = useSiteMetadata();

  return (
    <>
      <header className={`header ${variant ? `header--${variant}` : ''}`}>
        <div className="header__container">
          <h1>
            <Link className="header__logo" to="/">
              CRAFT
            </Link>
          </h1>

          <div
            dangerouslySetInnerHTML={{__html: descriptionHtml}}
            className="header__text"
          />
        </div>
      </header>

      <Helmet>
        <link rel="shortcut icon" href={Favicon} type="image/svg" />
        <link rel="alternate" href="/rss.xml" type="application/rss+xml" />

        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={siteUrl + OpenGraphImage} />
        <meta property="og:image:width" content="2000" />
        <meta property="og:image:height" content="1024" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@mirego" />
        <meta name="twitter:creator" content="@mirego" />

        {process.env.GATSBY_ONETRUST_SITE_ID && (
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script={process.env.GATSBY_ONETRUST_SITE_ID}
          ></script>
        )}

        {process.env.GATSBY_ONETRUST_SITE_ID && (
          <script type="text/javascript">function OptanonWrapper() {}</script>
        )}
      </Helmet>

      {children}

      <footer className="footer">
        Built with ♥ (and{' '}
        <a
          href="https://www.gatsbyjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gatsby.js
        </a>
        ) by the team @ <a href="https://www.mirego.com">Mirego</a>.{' '}
        <a className="footer__rss-link" href="/rss.xml" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792">
            <path
              d="M596.61 1154.17q0 80-56 136t-136 56q-80 0-136-56t-56-136q0-80 56-136t136-56q80 0 136 56t56 136zm512 123q2 28-17 48-18 21-47 21h-135q-25 0-43-16.5t-20-41.5q-22-229-184.5-391.5t-391.5-184.5q-25-2-41.5-20t-16.5-43v-135q0-29 21-47 17-17 43-17h5q160 13 306 80.5t259 181.5q114 113 181.5 259t80.5 306zm512 2q2 27-18 47-18 20-46 20h-143q-26 0-44.5-17.5t-19.5-42.5q-12-215-101-408.5t-231.5-336q-142.5-142.5-336-231.5t-408.5-102q-25-1-42.5-19.5t-17.5-43.5v-143q0-28 20-46 18-18 44-18h3q262 13 501.5 120t425.5 294q187 186 294 425.5t120 501.5z"
              fill="currentColor"
            />
          </svg>
        </a>
        <div className="footer__cookie-and-privacy-policy-row">
          <div className="footer__cookie-button">
            <span id="ot-sdk-btn" className="ot-sdk-show-settings"></span>
          </div>

          <a
            href="https://www.mirego.com/en/privacy-policy"
            className="footer__privacy-policy"
          >
            Privacy policy
          </a>
        </div>
      </footer>
    </>
  );
}
